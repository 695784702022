import React from "react"
import useForm from "../useForm"
import validate from '../validateInfo'
import { useState, useEffect } from 'react';
export default function Contact() {

    const [isSubmitted, setIsSubmitted] = useState(false);

    function submitForm() {
        setIsSubmitted(true);
    }
    const { handleChange, handleSubmit, values, errors } = useForm(validate, submitForm);

    return (
        <div className="mainBody">
            <div className="form-content-right">
                {!isSubmitted ? ( // Render different content based on the `submitted` state
                    <form className="form" onSubmit={handleSubmit}>
                        <h1
                            style={{
                                fontSize: '3rem',
                                fontFamily: 'Arial, sans-serif',
                                padding: '25px 0 40px', /* 200px top and bottom padding, 0px left and right padding */
                            }}
                        >
                            Contact Me
                        </h1>
                        <div className="name-forms">
                            <div className="form-inputs">
                                {/*<label htmlFor="firstname" className="form-label">
                                First Name
                            </label>*/}
                                <input
                                    id="name"
                                    type="text"
                                    name="name"
                                    className="name-input"
                                    placeholder="Name"
                                    value={values.name}
                                    onChange={handleChange}
                                    style={{ backgroundColor: 'white' }}
                                />
                                {errors.name && <p>{errors.name}</p>}
                            </div>

                        </div>

                        <div className="form-inputs">
                            {/*<label htmlFor="email" className="form-label">
                                Email
                            </label>*/}
                            <input
                                id="email"
                                type="email"
                                name="email"
                                className="email-input"
                                placeholder="Email"
                                value={values.email}
                                onChange={handleChange}
                                style={{ backgroundColor: 'white' }}
                            />
                            {errors.email && <p>{errors.email}</p>}
                        </div>
                        <div className="form-inputs">
                            {/* <label htmlFor="message" className="form-label">
        Message
    </label> */}
                            <textarea
                                id="message"
                                name="message"
                                className="message-input"
                                placeholder="Message"
                                value={values.message}
                                onChange={handleChange}
                                rows={4} // Set the number of rows for the textarea
                                style={{ width: '100%' }} // Set the width to 100% of the container
                            />
                            {errors.message && <p>{errors.message}</p>}
                        </div>

                        <div

                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                            <button
                                className="form-input-btn"
                                type="submit"
                            >
                                <div className="button_text">
                                    Send Message
                                </div>
                            </button>
                        </div>
                        {/*<span className="form-input-login">Some other text</span>*/}
                    </form>) : (<div
                        className="success_message"
                    >
                        Thank you for the message!
                    </div>)
                }
            </div>
        </div>
    );

}