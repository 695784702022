import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCysQ_rrU1eGcTclQXvjuQE4yB6rMCd1Ys",
  authDomain: "mita-ab76d.firebaseapp.com",
  databaseURL: "https://mita-ab76d-default-rtdb.firebaseio.com",
  projectId: "mita-ab76d",
  storageBucket: "mita-ab76d.appspot.com",
  messagingSenderId: "625152551538",
  appId: "1:625152551538:web:c608fcbf0a34feaaee70eb"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const mitaFormDB = firebase.database().ref('mitaForm');

const useForm = (validate, submit) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [values, setValues] = useState({
    name: '',
    email: '',
    message: '', // Added message field
  });
  const [errors, setErrors] = useState({});

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  useEffect(() => {
    const { name , email, message } = values;
    if (Object.keys(errors).length === 0 && isSubmitting) {
      mitaFormDB
        .push({
          name: name,
          email: email,
          message: message,
        })
        .then(() => {
          setValues({
            name: '',
            email: '',
            message: '', // Reset message field
          });
          submit();
        })
        .catch((error) => {
          alert(error.message);
        });
    }
    console.log(errors);
  }, [errors, values]); // Include both errors and values in the dependency array
  

  const handleSubmit = e => {
    e.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  return { handleChange, values, handleSubmit, errors };
};

export default useForm;
