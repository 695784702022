import { BsArrowRight } from 'react-icons/bs';
import { FaGripLinesVertical } from 'react-icons/fa';

export default function Home() {
    const fontStyle = { fontFamily: 'Arial' };

    return (
        <div className="mainBody" style={fontStyle}>
            <div className="container">
                <div>
                    <div className="textBody">
                        <p style={{ ...fontStyle }}>
                            We are at a critical juncture for tech x biology. Biological phenomena and molecular drivers of disease are being understood with finer granularity. We have better tools to probe and record biology. However, the vast majority of the value from our improving understanding of biology has yet to be unlocked with domain-specific computational approaches.
                        </p>

                        <p style={fontStyle}>
                            Now is the time to act.
                        </p>

                        <p style={fontStyle}>
                            New computational strategies can uncover novel biology, as well as accelerate our ability to capitalize on that understanding for clinical benefit. Like all technology S-curves, we will build progress incrementally at first, then exponentially over time due to compounding effects. Uncovering high biological conviction targets, overcoming engineering challenges in delivery, improving payloads, and much more are coming on the horizon.
                        </p>
                    </div>

                    <div className="textBody">
                        <h2 style={fontStyle}>Our World Thesis:</h2>

                        <div className="bullet-points">
                            <p style={fontStyle}>
                                The primitives of reading, writing, and engineering biology are rapidly dropping in cost, and improving in quality. These will unlock new engineered approaches to detecting, treating, and preventing disease.
                            </p>

                            <p style={fontStyle}>
                                The greatest value exists in the intersection of multiple fields (computation, biology, physics, chemistry). We are in the early innings of identifying and capturing the value from this intersection.
                            </p>

                            <p style={fontStyle}>
                                Compounding games, in relationships, technology, and companies, are the only games worth playing.
                            </p>
                        </div>
                    </div>

                    <div className="textBody">
                        <h2 style={fontStyle}>Why are we forming a fund? </h2>

                        <div className="bullet-points">
                            <p style={fontStyle}>
                                Savvy funds in the tech x bio space are rare - the barrier to investing is often high given specialized knowledge necessary to reach conviction. Differentiated capital backed by other founders is even rarer; we bring a founder perspective to investing in this space - bringing our expertise, energy, network, and insights to bear to give founders we work with the absolute best chance of success.
                            </p>

                            <p style={fontStyle}>
                                We flat out believe the thesis and want to do whatever we can to create this future. The best way to predict the future is to create it. We predict that our understanding of biology is ready for a step level change and computation is the path to get there; we are creating a fund to help jumpstart the incredible entrepreneurs who also believe this in their core.
                            </p>
                        </div>
                    </div>

                    <div className="textBody">
                        <h2 style={fontStyle}>Founders / Teams We Are Looking For:</h2>

                        <div className="bullet-points">
                            <p style={fontStyle}>
                                Strong communicators.
                            </p>

                            <p style={fontStyle}>
                                Clear, concise thinking - as represented in writing or speech; content makes it clear you have a track record.
                            </p>

                            <p style={fontStyle}>
                                Stamina - you are in it for the long haul.
                            </p>

                            <p style={fontStyle}>
                                Dream is massive, but starting point is specific, targeted, and useful.
                            </p>

                            <p style={fontStyle}>
                                Humble - tons to learn, willing to do whatever it takes.
                            </p>
                        </div>
                    </div>

                    <div className="textBody">
                        <h2 style={fontStyle}>Why the name MITA.vc?</h2>
                        <p>MITA stands for Man *or woman* In The Arena and alludes to a quote from Teddy Roosevelt:  </p>


                        <p style={fontStyle}>
                            “It is not the critic who counts; not the man who points out how the strong man stumbles, or where the doer of deeds could have done them better. The credit belongs to the man who is actually in the arena, whose face is marred by dust and sweat and blood; who strives valiantly; who errs, who comes short again and again, because there is no effort without error and shortcoming; but who does actually strive to do the deeds; who knows great enthusiasms, the great devotions; who spends himself in a worthy cause; who at the best knows in the end the triumph of high achievement, and who at the worst, if he fails, at least fails while daring greatly, so that his place shall never be with those cold and timid souls who neither know victory nor defeat."
                        </p>

                        <p style={fontStyle}>
                            We believe the credit belongs to the founders who are creating the future. We know and identify with the highs and lows of entrepreneurship struggle, and want to partner with those who put their blood, sweat, tears - and most importantly - precious years of their life - into a worthy cause.
                        </p>
                    </div>

                    <div className="textBody">
                        <h2 style={fontStyle}>Themes We Want to Invest In:</h2>

                        <h4 className='smallHeading' style={fontStyle}>Better Biology </h4>
                        <div className="bullet-points">
                            <p style={fontStyle}>
                                Next-gen precision medicine - separating out biological phenomena by patient pop, and then figuring out how to drug it.
                            </p>

                            <p style={fontStyle}>
                                Quantitatively measuring & understanding biology which we’d like to drug for tx effect (novel target discovery, validation of target biology).
                            </p>
                            <p style={fontStyle}>
                                Novel computationally driven ways to uncover useful, new biology.
                            </p>
                        </div>

                        <h4 className='smallHeading' style={fontStyle}>Better Molecule Design </h4>
                        <div className="bullet-points">
                            <p style={fontStyle}>
                                Computationally driven ways to design more effective molecules
                                <span className="arrow-icon"><BsArrowRight /></span>
                                must be pointed to attacking a specific industrialization problem.
                            </p>

                            <h4 className='smallHeading' style={fontStyle}>Generally speaking, anything that moves POS - not just scale and speed of tx discovery</h4>
                            <p style={fontStyle}>
                                Better translational models, often computationally driven.
                            </p>

                            <p style={{ ...fontStyle, marginLeft: '5rem' }}>
                                Rare disease will get pre-clinical value, whereas oncology won’t - because the translation rate is much higher.
                            </p>

                            <p style={fontStyle}>
                                Better clinical dev, often algorithmically designed.
                            </p>

                            <p style={fontStyle}>
                                Improved clinical analytics <span className="arrow-icon"><BsArrowRight /></span> improved development outcomes.
                            </p>
                        </div>
                    </div>

                    <div className="textBody">
                        <h2 style={fontStyle}>Why should founders work with MITA?</h2>
                        <div className="bullet-points">
                            <p style={fontStyle}>
                                $100k equity investment.
                            </p>
                            <p style={fontStyle}>
                                Tried and true recruiting tactics especially for difficult to hire cross-functional tech x bio roles <span className='vertical'>||</span> network of vetted recruiters.
                            </p>
                            <p style={fontStyle}>
                                Best practices on indication selection  <span className='vertical'>||</span> network of founders that have gone through your indication selection process before.
                            </p>
                            <p style={fontStyle}>
                                Best practices on BD  <span className='vertical'>||</span> network of BD contacts in any indication of interest.
                            </p>
                            <p style={fontStyle}>
                                Best practices on fundraising  <span className='vertical'>||</span> investor relations  <span className='vertical'>||</span> best practices on negotiating term sheets  <span className='vertical'>||</span> catalogue of decks from companies that have gone on to raise immense amounts.
                            </p>
                            <p style={fontStyle}>
                                Support network of CEOs who are a couple of rounds ahead who you can call.
                            </p>
                            <p style={fontStyle}>
                                Direct line to chat through CEO issues that you have nowhere else to go with.
                            </p>
                            <p style={fontStyle}>
                                My background: 3x operator, first-time founder in biotech, FTE #6 at Benchling, former career in investment banking and private equity at Goldman Sachs / KKR. Penn Life Sciences & Management graduate.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
